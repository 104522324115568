import app from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
    apiKey: "AIzaSyDI9SQ4S2moZb2G7mwaGs5wmMiCU4w_eXA",
    authDomain: "boxup-io.firebaseapp.com",
    databaseURL: "https://boxup-io.firebaseio.com",
    projectId: "boxup-io",
    storageBucket: "boxup-io.appspot.com",
    messagingSenderId: "1037639517068",
    appId: "1:1037639517068:web:57d8a8c6d3b92c74678ca9",
    measurementId: "G-6T44NEG1EP"
};

export const fb = !app.apps.length ? app.initializeApp(config) : app.app();
export const db = app.firestore();