import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { 
    Page, Layout, Card, Checkbox, Banner,
    FormLayout, TextField, PageActions, Spinner,
    Select, Toast, Frame, Tag, Button
} from '@shopify/polaris';
import { TitleBar } from '@shopify/app-bridge-react';
import { getVariants, updateAlgolia } from './../../actions/';
import ReactTags from 'react-tag-autocomplete';
import { tags } from './../../actions/tags';

class CommissionComponent extends React.Component {

    state = {
        error: '',
        loading: true,
        product: {variants: [], title: '...'},
        toast: false,
        active: false,
        tags: [],
        suggestions: [],
        bulkAmount: '',
        bulkType: ''
    }

    componentDidMount = async () => {
        window.scrollTo(0,0);
        // tags setup
        let suggestions = [];
        tags.map(tag => {
            suggestions.push({
                id: tag,
                name: tag
            });
        });

        let productId = this.props.match.params.id;
        let { shop, id } = this.props.shop.info;
        if (productId) {
            getVariants(shop, id, productId)
            .then(async(product) => {
                const algoliasearch = require("algoliasearch");
                const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
                const index = client.initIndex('ProductListings');
                let algoliaProduct = await index.getObject(`${id}==${productId}`);
                algoliaProduct.commission.map((com) => {
                    product.variants.map((variant, i) => {
                        if (variant.id === com.id) {
                            product.variants[i].type = com.type;
                            product.variants[i].value = com.value;
                            product.variants[i].formatted_price = com.formattedPrice;
                            product.variants[i].price = com.price;
                            product.variants[i].commissionActive = com.commissionActive;
                        }
                    });
                });
                let setTags = algoliaProduct._tags.map(tag => {
                    return {
                        id: tag,
                        name: tag
                    }
                });
                this.setState({
                    product, 
                    loading: false,
                    active: algoliaProduct.active,
                    tags: setTags || [],
                    suggestions
                });
                window.$("input[type='number']").maskAsNumber({min: 0, decimals: 2});
            })
            .catch((err) => {
                console.log(err)
                this.props.history.goBack();
            });
        } else {
            this.props.history.goBack();
        }
    }

    updateCommission = (key, val, x) => {
        let { product } = this.state;
        let { variants } = product;
        variants[x][key] = val;
        product.variants = variants;
        this.setState({product});
    }

    applyToAll = () => {
        let { product } = this.state;
        let { variants } = product;
        variants.map((v, i) => {
            variants[i].type = this.state.bulkType;
            variants[i].value = this.state.bulkAmount;
        });
        product.variants = variants;
        this.setState({product});
    }

    includeAll = () => {
        let product = this.state.product;
        let variants = product.variants;
        variants.map((v,i) => {
            variants[i].commissionActive = true;
        });
        product.varaints = variants;
        this.setState({product});
    }

    saveCommission = async () => {
        this.setState({error: '', loading: true});
        let commission = [];
        let numActive = this.state.product.variants.filter(variant => variant.commissionActive);
        let save = true;
        let addedTags = this.state.tags.map(tag => tag.name);
        this.state.product.variants.map((v) => {
            if ((v.commissionActive && (!v.value || !v.type)) || numActive.length === 0) {
                this.setState({
                    error: 'Please select a Type and Value for all available variants.',
                    loading: false
                });
                save = false;
            }
            commission.push({
                type: v.type,
                value: v.value,
                formattedPrice: v.formatted_price,
                price: v.price,
                id: v.id,
                commissionActive: v.commissionActive
            });
        });
        if (save) {
            let shopId = this.props.shop.info.id;
            updateAlgolia(`${shopId}==${this.state.product.product_id}`, commission, this.state.active, addedTags)
            .then((res) => {
                this.setState({toast: true}, () => {
                    setTimeout(() => {
                        this.props.history.push("/account/?tab=3");
                    }, 2000);
                });
            })
            .catch((err) => {
                this.setState({error: err, loading: false});
            });
        }
    }

    tagDelete = (i) => {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        this.setState({ tags });
    }

    addTag = (tag) => {
        if (this.state.tags.length < 3) {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
        }
    }

    render() {
        let { product } = this.state;
        return <Page
        >
            <TitleBar
                title={`Set Commission for ${product.title}`}
            />
            <Layout>
                <Layout.Section>
                    {this.state.error !== '' &&
                    <Banner
                        title={this.state.error}
                        status="critical"
                    >
                        <p>You must have at least 1 available variant to save this commission.</p>
                    </Banner>
                    }
                </Layout.Section>
                <Layout.Section>
                    <Card title={`Commission Settings for ${product.title}`} sectioned>
                        {this.state.loading && 
                        <div style={{textAlign: 'center'}}>
                            <Spinner size="large" color="teal" />
                        </div>
                        }
                        {!this.state.loading && 
                        <>
                        <Card.Section>
                            <Checkbox
                                label="Active on the Boxup Marketplace"
                                checked={this.state.active}
                                onChange={() => this.setState({active: !this.state.active})}
                            />
                            <div style={{marginTop:15+'px'}}>
                                <Tag>You can add up to 3 tags to help dropshippers find it</Tag>
                            </div>
                            <ReactTags
                                tags={this.state.tags}
                                suggestions={this.state.suggestions}
                                handleDelete={this.tagDelete}
                                handleAddition={this.addTag} 
                                placeholder="Add product tags"
                                maxSuggestionsLength={2}
                            />
                        </Card.Section>
                        {this.state.product.variants.length > 1 && 
                        <Card.Section title="Bulk Actions">
                            <FormLayout>
                                <FormLayout.Group condensed>
                                    <div style={{marginTop:22+'px'}}>
                                        <Button onClick={this.includeAll}>
                                            Include All Variants
                                        </Button>
                                    </div>
                                    <Select
                                        label="Set All Commission Types"
                                        options={[
                                            {label: 'Select type', value: ''},
                                            {label: 'Percentage (%) per sale', value: 'percentage'},
                                            {label: 'Fixed value per sale', value: 'fixed'}
                                        ]}
                                        onChange={(val) => this.setState({bulkType: val})}
                                        value={this.state.bulkType || ''}
                                        id="bulkType"
                                    />
                                    <TextField 
                                        type="number" 
                                        min={0}
                                        label="Set All Amounts" 
                                        onChange={(val) => this.setState({bulkAmount: val})}
                                        value={this.state.bulkAmount || 0.00}
                                        id="bulkAmount"
                                    />
                                    <div style={{marginTop:22+'px'}}>
                                        <Button primary onClick={this.applyToAll}>
                                            Apply To All
                                        </Button>
                                    </div>
                                </FormLayout.Group>
                            </FormLayout>
                        </Card.Section>
                        }
                        </>
                        }
                        {!this.state.loading && product.variants.map((item, x) => {
                            return <Card.Section key={x} title={`Variant ${x+1}: ${item.title}`}>
                                <div style={{marginTop:10+'px'}}>
                                    <FormLayout>
                                        <FormLayout.Group>
                                            <Select
                                                label="Commission Type"
                                                options={[
                                                    {label: 'Select type', value: ''},
                                                    {label: 'Percentage (%) per sale', value: 'percentage'},
                                                    {label: 'Fixed value per sale', value: 'fixed'}
                                                ]}
                                                onChange={(val) => this.updateCommission("type", val, x)}
                                                value={item.type || ''}
                                            />
                                            <TextField 
                                                type="number" 
                                                label="Amount" 
                                                onChange={(val) => this.updateCommission("value", val, x)} 
                                                value={item.value || 0.00}
                                                min={0}
                                            />
                                            <div style={{marginTop: 23+'px'}}>
                                                <Checkbox
                                                    label="Include this variant"
                                                    checked={item.commissionActive || false}
                                                    onChange={(val) => this.updateCommission("commissionActive", val, x)}
                                                />
                                            </div>
                                        </FormLayout.Group>
                                    </FormLayout>
                                </div>
                            </Card.Section>
                        })}
                    </Card>
                    <PageActions
                        primaryAction={{
                            content: 'Save & Set Commission',
                            onAction: () => this.saveCommission(),
                            loading: this.state.loading
                        }}
                        secondaryActions={[
                            {
                                content: 'Cancel',
                                onAction: () => this.props.history.push("/account/?tab=3")
                            },
                        ]}
                    />
                </Layout.Section>
            </Layout>
            <Frame>
                {this.state.toast && 
                <Toast content="Commission Saved" onDismiss={() => this.setState({toast: false})} />
                }
            </Frame>
        </Page>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CommissionComponent));