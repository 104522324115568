import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from './../../actions/';
import { Page, Tabs } from '@shopify/polaris';
import Setup from './checkout';
import Dashboard from './dashboard';
import Products from './products';
import AffiliateSettings from './affiliate';
import ReactQueryParams from 'react-query-params';
import { TitleBar } from '@shopify/app-bridge-react';

class HomeComponent extends ReactQueryParams {

    state = {
        tab: Number(this.queryParams.tab) || 0
    }

    render() {
        const tabs = [
            {
                id: 'dashboard',
                content: 'Dashboard',
                component: <Dashboard />,
            },
            {
                id: 'settings',
                content: 'Checkout Settings',
                component: <Setup/>
            },
            {
                id: 'dropship-settings',
                content: 'Dropshipping Settings',
                component: <AffiliateSettings/>
            }
        ];
        if (this.props.shop.info.market_enabled) {
            tabs.push({
                id: 'dropship',
                content: 'Dropshipping Products',
                component: <Products/>
            });
        }
        return <Page
        >
            <TitleBar
                title="BoxUp One-Page Checkout"
            />
            <Tabs tabs={tabs} selected={this.state.tab} onSelect={(selectedTabIndex) => this.setState({tab: selectedTabIndex})}>
                <div style={{marginTop: 30+'px'}}>
                    {tabs[this.state.tab].component}
                </div>
            </Tabs>
        </Page>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(HomeComponent));