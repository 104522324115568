export const tags = [
    "Clothing",
    "Shoes",
    "Jewellery",
    "Sports",
    "Technology",
    "Tech",
    "Computers",
    "Laptops",
    "Food",
    "Drink",
    "Energy",
    "Exercise",
    "Outdoors",
    "Tickets",
    "Woodwork",
    "Crafts",
    "Engineering",
    "Money",
    "Finance",
    "Phones",
    "Tablets",
    "Cases",
    "Bags",
    "Workout",
    "Gym",
    "Electronics",
    "Books",
    "Games",
    "Consoles",
    "Furniture",
    "MakeUp",
    "Sewing",
    "Music",
    "Digital",
    "Oils",
    "Lights",
    "Chairs",
    "Pants",
    "Jackets",
    "Socks",
    "Accessories",
    "Glass",
    "Flowers",
    "Engraved",
    "Cooking",
    "Gadgets",
    "Gifts",
    "Personalised",
    "Customised",
    "Vouchers",
    "Printing",
    "Cameras",
    "iPhone",
    "Apple",
    "Samsung",
    "Cables",
    "Plants",
    "Health",
    "Medicine",
    "Cleaning",
    "3D",
    "VR",
    "AR",
    "Graphics",
    "Art",
    "Photography",
    "Alcohol",
    "Vape",
    "Coffee",
    "Natural",
    "Beauty",
    "Watches",
    "TV",
    "Toys",
    "Kids",
    "Children",
    "Glasses",
    "Coding",
    "Wedding",
    "Dresses",
    "Smart",
    "Comfy",
    "Other",
    "Kitchen",
    "Bedroom",
    "Beds",
    "Men",
    "Women",
    "Fashion"
]