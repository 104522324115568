import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hideTip } from './../../actions/';
import { 
    Layout, Banner, Card, ResourceItem, ResourceList, 
    TextStyle, Select, Pagination, Thumbnail, Badge,
    TextField
} from '@shopify/polaris';

const options = [
    {label: '20 per page', value: "20"},
    {label: '50 per page', value: "50"},
    {label: '100 per page', value: "100"},
];

const activeOptions = [
    {label: 'Any', value: ''},
    {label: 'Active Only', value: '1'},
    {label: 'Inactive Only', value: '0'},
];

class Products extends React.Component {

    state = {
        loading: true,
        products: [],
        page: 0,
        perPage: 20,
        nextPage: false,
        prevPage: false,
        search: '',
        active: ''
    }

    componentDidMount = () => {
        this.searchProducts(null);
    }

    searchTerm = (search) => {
        this.setState({ search }, () => {
            this.searchProducts();
        });
    }

    searchProducts = (move) => {
        const algoliasearch = require("algoliasearch");
        const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY);
        let { page, perPage, search, active } = this.state;
        if (move) page = page + move;
        let activeFilter = (active !== '') ? `active=${active}` : '';
        const index = client.initIndex('ProductListings');
        index.search(search, {
            page: page,
            hitsPerPage: perPage,
            filters: activeFilter,
            facetFilters: [
                `shopId:${this.props.shop.info.id}`
            ]
        })
        .then((resp) => {
            let hasNext = (resp.nbPages > (resp.page+1)) ? true : false;
            let hasPrev = (resp.page > 0) ? true : false;
            this.setState({
                products: resp.hits,
                loading: false,
                nextPage: hasNext,
                prevPage: hasPrev,
                page: page
            });
        })
        .catch((err) => {
            console.log(err);
        });
    }

    setResults = (num) => {
        this.setState({
            page: 0,
            perPage: num,
            loading: true
        }, () => {
            this.searchProducts(null);
        });
    }

    setFilters = (active) => {
        this.setState({ active, page: 0 }, () => {
            this.searchProducts();
        });
    }

    render() {
        let { prevPage, nextPage, products, loading, search, perPage } = this.state;
        return <>
        {this.props.tips.products && 
        <Layout>
            <Layout.Section>
                <Banner
                    title="Products will only show once you have made them available to the BoxUp Sales Channel."
                    status="info"
                    onDismiss={() => this.props.hideTip("PRODUCT")}
                    >
                    <p>If you don't see the products here, go to the main 'Products' tab in your Shopify Admin menu, select the product and make it available to BoxUp.</p>
                </Banner>
            </Layout.Section>
        </Layout>
        }
        <Layout>
            <Layout.Section oneThird>
                <div style={{marginTop:20+'px'}}>
                    <TextField 
                        placeholder="Search..."
                        value={search} 
                        onChange={(val) => this.searchTerm(val)} 
                    />
                </div>
            </Layout.Section>
            <Layout.Section oneThird>
                <div style={{marginTop:20+'px'}}>
                    <Select
                        label="Show"
                        labelInline
                        options={activeOptions}
                        onChange={(val) => this.setFilters(val)}
                        value={this.state.active}
                    />
                </div>
            </Layout.Section>
            <Layout.Section oneThird>
                <div style={{marginTop:20+'px'}}>
                    <Select
                        label="Show"
                        labelInline
                        options={options}
                        onChange={(val) => this.setResults(val)}
                        value={perPage}
                    />
                </div>
            </Layout.Section>
        </Layout>
        <Layout>
            <Layout.Section>
                <div style={{marginTop:20+'px'}}>
                    <Card 
                        title="Dropshipping Marketplace Product Settings"
                    >
                        <Card.Section>
                            <p>Add/remove products from BoxUp Dropshipping Marketplace and set the commission for each variant.</p>
                            <ResourceList
                                resourceName={{singular: 'product', plural: 'products'}}
                                items={products}
                                loading={loading}
                                selectable={false}
                                idForItem={(item) => item.productId}
                                renderItem={(item) => {
                                    const { title, image, productId } = item;
                                    const media = <Thumbnail customer size="medium" source={image} />;
                                    const buttonTxt = (item.commission.length > 0) ? 'Update' : 'Set';
                                    const shortcutActions = [{
                                        content: buttonTxt + ' Commission', 
                                        onAction: () => this.props.history.push(`/account/commission/${productId}`)
                                    }];
                                    let active = (item.active)
                                        ? <Badge status="success">Active</Badge>
                                        : <Badge status="critical">Inactive</Badge>;
                                    let hasCommission = (item.commission.length > 0)
                                        ? <Badge status="success">Commission Set</Badge>
                                        : <Badge status="critical">Commission Not Set</Badge>;
                                    return (
                                        <ResourceItem
                                            id={productId}
                                            media={media}
                                            shortcutActions={shortcutActions}
                                            persistActions
                                        >
                                            <h3>
                                                <TextStyle variation="strong">{title}</TextStyle>
                                            </h3>
                                            <div className="list-label">{active}</div>
                                            <div className="list-label">{hasCommission}</div>
                                        </ResourceItem>
                                    );
                                }}
                            />
                        </Card.Section>
                        <Card.Section>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <Pagination
                                    hasPrevious={prevPage}
                                    onPrevious={() => {
                                        this.searchProducts(-1);
                                    }}
                                    hasNext={nextPage}
                                    onNext={() => {
                                        this.searchProducts(1);
                                    }}
                                />
                            </div>
                        </Card.Section>
                    </Card>
                </div>
            </Layout.Section>
        </Layout>
        </> 
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    hideTip
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Products));