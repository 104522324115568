import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommissionComponent from './../components/commission/';

export let Commission = props => {
    return <CommissionComponent/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(withRouter(Commission));