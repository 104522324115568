import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HomeComponent from './../components/home/';

export let Home = props => {
    return <HomeComponent/>
}

const mapStateToProps = state => {
    return state;
}
  
export default connect(
    mapStateToProps
)(withRouter(Home));