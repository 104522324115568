
const defaultState = {
    checkout: true,
    affiliate: true,
    products: true
}

export default function tips (state = defaultState, action) {
    switch (action.type) {
        case "HIDE_CHECKOUT_TIP":
            return {
                ...state,
                checkout: false
            }
        case "HIDE_AFFILIATE_TIP":
            return {
                ...state,
                affiliate: false
            }
        case "HIDE_PRODUCT_TIP":
            return {
                ...state,
                products: false
            }
        case "SHOW_CHECKOUT_TIP":
            return {
                ...state,
                checkout: true
            }
        case "SHOW_AFFILIATE_TIP":
            return {
                ...state,
                affiliate: true
            }
        case "SHOW_PRODUCT_TIP":
            return {
                ...state,
                products: true
            }
        default:
            return state;
    }
}