export function login(info) {
    return {
        type: "LOGIN",
        payload: {
            info
        }
    }
}

export function logout() {
    return {
        type: "LOGOUT"
    }
}

export function hideTip(tip) {
    return {
        type: `HIDE_${tip}_TIP`
    }
}

export function showTip(tip) {
    return {
        type: `SHOW_${tip}_TIP`
    }
}

// normal functions

const API_URL = process.env.REACT_APP_API_URL;

export function checkAuth(query) {
    return new Promise((resolve, reject) => {
        let parameters = [];
        for (var key in query) {
            parameters.push(key + '=' + query[key]);
        }

        let params = parameters.sort().join(query.hmac ? '&' : '');
        const endpoint = `${API_URL}/auth?${params}`;
    
        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });

    });
}

export function install(query) {
    return new Promise((resolve, reject) => {
        const { timestamp, shop, hmac, code, state } = query;
        const endpoint = `${API_URL}/auth/install?shop=${shop}&hmac=${hmac}&timestamp=${timestamp}&code=${code}&state=${state}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function updateAffiliate(id, on, desc, tags) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/affiliate`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "description": desc,
                "id": id,
                "on": on,
                "tags": tags
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function checkPlan(id) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/auth/plan?id=${id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function getVariants(shop, id, product) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/variants?id=${id}&shop=${shop}&product=${product}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function updateAlgolia(objectID, commission, active, addedTags) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/commission`;

        fetch(endpoint, {
            method: "POST",
            body: JSON.stringify({
                "commission": commission,
                "id": objectID,
                "active": active,
                "addedTags": addedTags
            })
        })
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err.error); 
        });
    });
}

export function confirmPlan(query) {
    return new Promise((resolve, reject) => {
        const { charge_id, id } = query;
        const endpoint = `${API_URL}/auth/confirm?id=${id}&charge_id=${charge_id}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}

export function storeScriptTag(id, enabled, button) {
    return new Promise((resolve, reject) => {
        const endpoint = `${API_URL}/shop/script?id=${id}&enabled=${enabled}&button=${button}`;

        fetch(endpoint)
        .then((data) => data.json())
        .then((res) => resolve(res))
        .catch((err) => { 
            console.log(err);
            reject(err); 
        });
    });
}