import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { AppProvider } from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';

import Layout from './containers/layout';
import Auth from './components/auth/';
import Install from './components/auth/install';
import Paid from './components/auth/paid';
import ErrorPage from './components/auth/error';

import { login, logout } from './actions';

// layout routes
import Home from './containers/home';
import Commission from './containers/commission';

class App extends React.Component {

    requireAuth = async () => {
        return this.props.shop.loggedIn;
    }
    
    render() {
        const config = {
            apiKey: process.env.REACT_APP_API_KEY, 
            shopOrigin: (this.props.shop.info) ? this.props.shop.info.shop : '',
            forceRedirect: false
        };
        return (
            <Router>
                <Switch>
                    <Route path="/account/" render={() => (
                        this.requireAuth() && this.props.shop.info ? (
                            <Provider config={config}>
                                <AppProvider>
                                    <Layout>
                                        <Route exact path="/account/" component={Home}/>
                                        <Route exact path="/account/commission/:id" component={Commission}/>
                                    </Layout>
                                </AppProvider>
                            </Provider>
                        ) : (
                            <Redirect to="/logout"/>
                        )
                    )}/>
                    <Route exact path="/auth" component={Auth} />
                    <Route exact path="/install" component={Install} />
                    <Route exact path="/paid" component={Paid} />
                    <Route exact path="/error" component={ErrorPage} />
                    <Route component={ErrorPage} />
                </Switch>
            </Router>
        )
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps =  {
    login,
    logout
}
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);