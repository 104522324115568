import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { login, hideTip, updateAffiliate } from './../../actions/';
import ReactTags from 'react-tag-autocomplete';
import { tags } from './../../actions/tags';
import { 
    Layout, AccountConnection, Link, Banner, 
    TextField, FormLayout, Button, Tag 
} from '@shopify/polaris';
import { createApp } from '@shopify/app-bridge';
import { Modal, Button as ModalButton } from '@shopify/app-bridge/actions';

class AffiliateSettings extends React.Component {

    state = {
        marketUpdating: false,
        description: this.props.shop.info.desc || '',
        error: '',
        saving: false,
        tags: [],
        suggestions: []
    }

    componentDidMount() {
        let suggestions = [];
        tags.map(tag => {
            suggestions.push({
                id: tag,
                name: tag
            });
        });
        let t = this.props.shop.info.tags || [];
        let setTags = t.map(tag => {
            return {
                id: tag,
                name: tag
            }
        });
        this.setState({suggestions, tags: setTags});
    }

    updateMarket = async(onOrOff) => {
        this.setState({error: '', saving: true});
        if (!this.state.description && onOrOff) {
            this.setState({error: 'Please enter a description.', saving: false});
        } else {
            let addedTags = this.state.tags.map(tag => tag.name);
            this.setState({marketUpdating: true}, async () => {
                let data = await updateAffiliate(this.props.shop.info.id, onOrOff, this.state.description, addedTags);
                this.props.login(data);
                this.setState({
                    marketUpdating: false,
                    description: data.desc,
                    saving: false
                });
            });
        }
    }

    updatedEnabled = async (onOrOff) => {
        if (!onOrOff) {
            const app = createApp({
                apiKey: process.env.REACT_APP_API_KEY,
                shopOrigin: this.props.shop.info.shop,
            });
            const okButton = ModalButton.create(app, {label: 'Disable', style: ModalButton.Style.Danger});
            const cancelButton = ModalButton.create(app, {label: 'Cancel'});
            cancelButton.subscribe(ModalButton.Action.CLICK, () => {
                modal.dispatch(Modal.Action.CLOSE);
            });
            okButton.subscribe(ModalButton.Action.CLICK, () => {
                this.updateMarket(onOrOff);
                modal.dispatch(Modal.Action.CLOSE);
            });
            const modalOptions = {
                title: 'Disable dropshipping?',
                message: 'Disabling will de-activate all products on the BoxUp dropshipping platform. You will have to activate them again in the future.',
                footer: {
                    buttons: {
                        primary: okButton,
                        secondary: [cancelButton],
                    }
                }
            }
            const modal = Modal.create(app, modalOptions);
            modal.dispatch(Modal.Action.OPEN);
        } else {
            this.updateMarket(onOrOff);
        }
    }

    saveDesc = async() => {
        let { market_enabled } = this.props.shop.info;
        this.updatedEnabled(market_enabled);
    }

    tagDelete = (i) => {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        this.setState({ tags });
    }

    addTag = (tag) => {
        if (this.state.tags.length < 3) {
            const tags = [].concat(this.state.tags, tag);
            this.setState({ tags });
        }
    }

    render() {
        let { market_enabled } = this.props.shop.info;
        let { marketUpdating } = this.state;
        // marketplace settings
        const marketButtonText = market_enabled ? 'Disable' : 'Enable';
        const marketDetails = market_enabled 
            ? <p style={{color:'green'}}>Currently Enabled</p>
            : <p style={{color:'red'}}>Currently Disabled</p>;
        const marketTerms = market_enabled 
            ? null
            : <p>By clicking <strong>Enable</strong>, you agree to accept BoxUp.io’s{' '}
                <Link external={true} url="https://boxup.io/terms">terms and conditions</Link></p>;
        return <Layout>
            {this.props.tips.affiliate && 
            <Layout.Section>
                <Banner
                    title="These settings apply to BoxUp's Dropshipping Marketplace."
                    status="info"
                    onDismiss={() => this.props.hideTip("AFFILIATE")}
                    >
                    <p>Once enabled, you will need to set the commission for each product.</p>
                </Banner>
            </Layout.Section>
            }
            <Layout.AnnotatedSection
                title="Sell on BoxUp Marketplace"
                description="You will need to setup products and dropshipping commission after enabling."
            >
                <AccountConnection
                    avatarUrl="/assets/image/affiliate.png"
                    connected={market_enabled}
                    title="Enable dropshippers to sell on your behalf"
                    action={{
                        content: marketButtonText,
                        loading: marketUpdating,
                        onAction: () => this.updatedEnabled(!market_enabled),
                    }}
                    details={marketDetails}
                    termsOfService={marketTerms}
                />
                <div style={{marginTop: '40px'}}>
                <FormLayout>
                    <TextField
                        label="Store Description"
                        value={this.state.description}
                        onChange={(val) => this.setState({description: val})}
                        multiline
                        placeholder="Let the dropshippers know about your store."
                        error={this.state.error}
                        maxLength={250}
                    />
                    <div style={{marginTop:15+'px'}}>
                        <Tag>You can add up to 3 tags to help dropshippers find you when searching</Tag>
                    </div>
                    <ReactTags
                        tags={this.state.tags}
                        suggestions={this.state.suggestions}
                        handleDelete={this.tagDelete}
                        handleAddition={this.addTag} 
                        placeholder="Add tags to describe your store"
                        maxSuggestionsLength={2}
                    />
                    <Button 
                        onClick={() => this.saveDesc()}
                        loading={this.state.saving}
                    >Update</Button>
                </FormLayout>
                </div>
            </Layout.AnnotatedSection>
        </Layout>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login,
    hideTip
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AffiliateSettings));