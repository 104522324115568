import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { db } from './../../actions/settings';
import { storeScriptTag, hideTip, login } from './../../actions/';
import { Layout, AccountConnection, Link, RadioButton, Stack, Card, Banner } from '@shopify/polaris';

class Setup extends React.Component {

    state = {
        storeUpdating: false,
        button: 'add'
    }

    updatedEnabled = async (option, onOrOff) => {
        let updateWhich = (option === 'store_enabled') 
            ? 'storeUpdating' 
            : 'marketUpdating';
        this.setState({[updateWhich]: true}, async () => {
            let shopRef = db
                .collection("Shops")
                .doc(this.props.shop.info.id);
            await shopRef
                .update({
                    [option]: onOrOff,
                    button: this.state.button
                });
            let shop = await shopRef
                .get();
            this.props.login(shop.data());
            this.setState({[updateWhich]: false});
        });
    }

    storeEnabled = async (store_enabled) => {
        this.setState({storeUpdating: true}, async () => {
            let data = await storeScriptTag(this.props.shop.info.id, store_enabled, this.state.button);
            this.props.login(data);
            this.setState({storeUpdating: false});
        });
    }

    setButton = async (btnType) => {
        this.setState({button: btnType}, async() => {
            let { store_enabled } = this.props.shop.info;
            await this.storeEnabled(store_enabled);
        });
    }

    render() {
        let { store_enabled, button } = this.props.shop.info;
        let { storeUpdating } = this.state;

        // on store settings
        const buttonText = store_enabled ? 'Disable' : 'Enable';
        const storeDetails = store_enabled 
            ? <p style={{color:'green'}}>Currently Enabled</p>
            : <p style={{color:'red'}}>Currently Disabled</p>;
        const storeTerms = store_enabled 
            ? <p>The button will automatically appear for all products that you make available to BoxUp.</p> 
            : <p>By clicking <strong>Enable</strong>, you agree to accept BoxUp.io’s{' '}
                <Link external={true} url="https://boxup.io/terms">terms and conditions</Link></p>;

        return <Layout>
            {this.props.tips.checkout && 
            <Layout.Section>
                <Banner
                    title="These settings control the checkout that appears on your own store"
                    status="info"
                    onDismiss={() => this.props.hideTip("CHECKOUT")}
                    >
                    <p>You do not need to enable this if you're just looking to enable affiliates to sell your products. 
                        For that, you can see 'Affiliate Settings'.
                    </p>
                </Banner>
            </Layout.Section>
            }
            <Layout.AnnotatedSection
                title="Show on your own store"
                description="We'll automaticall place a button on your product pages, enabling One-Page Checkout."
            >
                <Card>
                <AccountConnection
                    avatarUrl={"/assets/image/store.png"}
                    connected={store_enabled}
                    title="Enable One-Page Checkout on your store"
                    action={{
                        content: buttonText,
                        loading: storeUpdating,
                        onAction: () => this.storeEnabled(!store_enabled),
                    }}
                    details={storeDetails}
                    termsOfService={storeTerms}
                />
                <Card.Section>
                    <Stack vertical>
                        <RadioButton
                            label="Add Quick Buy as an additional option"
                            helpText="Customers can also choose to checkout using the Shopify checkout page"
                            id="add"
                            name="add"
                            value="add"
                            checked={(button === 'add')}
                            onChange={(checked) => this.setButton((checked) ? 'add' : 'replace')}
                        />
                        <RadioButton
                            label="Replace your default buy button"
                            helpText="Customers will only see the new Quick Buy button"
                            checked={(button === 'replace')}
                            id="replace"
                            name="replace"
                            value="replace"
                            onChange={(checked) => this.setButton((checked) ? 'replace' : 'add')}
                        />
                    </Stack>
                    </Card.Section>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    hideTip,
    login
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Setup));