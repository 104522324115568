import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from './../../actions/';
import { Layout, Card, TextStyle } from '@shopify/polaris';
import { currencies } from './../../actions/currencies';

class Dashboard extends React.Component {

    state = {
        currency: '',
        totalPaid: 0,
        totalOrders: 0
    }

    componentDidMount() {
        let { totalPaid, totalOrders, currency } = this.props.shop.info;
        console.log(currency)
        let currencySymbol = currencies[currency].symbol;
        this.setState({
            currency: currencySymbol,
            totalOrders,
            totalPaid
        });
    }

    render() {
        let { currency, totalPaid, totalOrders } = this.state;
        return <Layout>
            <Layout.Section oneThird>
                <Card>
                <Card.Section>
                        <p style={{
                            fontSize: 50,
                            textAlign: 'center',
                            paddingTop: 60,
                            paddingBottom: 80,
                            color: '#9ed694'
                        }}>
                            {currency}{totalPaid}
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <TextStyle variation="strong">Total Paid</TextStyle>
                        </p>
                    </Card.Section>
                </Card>
            </Layout.Section>
            <Layout.Section oneThird>
                <Card>
                    <Card.Section>
                        <p style={{
                            fontSize: 50,
                            textAlign: 'center',
                            paddingTop: 60,
                            paddingBottom: 80 
                        }}>
                            {totalOrders}
                        </p>
                        <p style={{textAlign: 'center'}}>
                            <TextStyle variation="strong">Total Orders</TextStyle>
                        </p>
                    </Card.Section>
                </Card>
            </Layout.Section>
            <Layout.Section oneThird>
            </Layout.Section>
        </Layout>
    }
}

const mapStateToProps = state => {
    return state;
}

const mapDispatchToProps = {
    login
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Dashboard));