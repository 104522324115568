import React from 'react';
import { AppProvider, Spinner } from '@shopify/polaris';

export const LoadingPage = ({shop}) => {
    return (
        <div style={{backgroundColor:'#f4f6f8',height:'100%',margin:'auto',width:'100%',position:'absolute'}}>
            <div style={{left: 50+'%', height: 100+'%', top: 50+'%', marginTop:-50+'px', marginLeft: -25+'px', position: 'absolute'}}>
                {shop !== "" && 
                <AppProvider 
                    apiKey={process.env.API_KEY}
                    shopOrigin={shop}
                    forceRedirect={process.env.FORCE_REDIRECT}
                >
                    <Spinner size="large" color="teal" />
                </AppProvider>
                }
                {shop === "" && 
                <AppProvider 
                    apiKey={process.env.API_KEY}
                    forceRedirect={process.env.FORCE_REDIRECT}
                >
                    <Spinner size="large" color="teal" />
                </AppProvider>
                }
            </div>
        </div>
    )
}